import $ from "jquery";
import { combineReducers } from 'redux';
import { matchPath } from 'react-router';
import reduceReducers from 'reduce-reducers';
import { handleActions } from 'redux-actions';
import memoizeOne from 'memoize-one';
import update from 'immutability-helper';
import {
	keyAdminTagNameAll,
	keyAdminTagSimpleList,
	keyAdminTagList,
	keyAgentAreas,
	keyAgentTemplates,
	keyAgents,
	keyFolders,
	keyAgentGroups,	//lwb test
	keyAreas,
	keyOrganizationOverviewAreas,
	keyChannels,
	keyCloseErrand,
	keyCloseErrands,
	keyDeleteErrands,
	keyErrandAgentStatistics,
	keyAreasAgentsData,
	keyErrandFetchAreaErrandCount,
	keyGetKnowledgeBaseList,
	keyErrandList,
	keyErrandListChat,
	keyChatForwardArea,
	keyChatForwardAgent,
	keyErrandPreview,
	keyErrandRemoveTemporaryAttachment,
	keyErrandUploadAnswerAttachment,
	keyErrandWorkflowSettings,
	keyGetErrands,
	keyGetOneRelatedErrand,
	keyLoadBasicErrand,
	keyQueueToMe,
	keyReloadOneErrand,
	keyForwardToArea,
	keyMoveToFolder,
	keyForwardToAgent,
	keyRetunToInbox,
	keyLinkErrand,
	keyWFExpertQueries,
	keyAgentSetErrandView,
	keyGetClientAvatar,
	keyErrandUpdateLockToMe,
	keyFetchExternalExpertThread,
	keyGetSlaTime,
	keyConnectedAgentAreas,
	keyStatisticsAgentAreas,
	keyCreateManualErrand,
	keyIncomingEmailErrands,
	keyAllActiveErrands,
	keyAllActiveAgents,
	keyErrandMyErrands,
	keyFetchForwardedErrands,
	keyFetchIncomingCollaborations,
	keyFetchDueErrandsInAWeek,
	keyFetchExpiringErrands,
	keyDeleteLaunchpadWidgets,
	keyAddLaunchpadWidgets,
	keyBulkSendErrands,
	keyQueueBasicErrandInfo

} from '../constants/keys';
import {
	CHANGE_SELECTED_FILTER_AREAS,
	NORMALIZE_AGENTS,
	SELECT_ERRAND_IN_ERRAND_LIST,
	SELECT_AREA_IN_AREA_LIST,
	SELECT_ALL_ERRANDS_IN_ERRAND_LIST,
	SELECT_ALL_ERRANDS_WITHOUT_CHAT,
	SELECT_ALL_AREAS,
	SELECT_TOGGLE_SORT_ERRAND_LIST,
	SET_CURRENT_ERRAND,
	SYNC_ACQUIRED_STATE,
	SYNC_ALL_BASIC_ERRAND_DATA,
	SYNC_RELATED_ERRANDS,
	FOCUS_ERRAND_LIST_SORT,
	SELECT_TOGGLE_PRIORITY_FILTER,
	SELECT_TOGGLE_AREAS_FILTER,
	SELECT_TOGGLE_AGENTS_FILTER,
	SELECT_TOGGLE_TAGS_FILTER,
	CHANGE_CONTEXT,
	SET_MY_ERRANDS_FOLDER,
	SET_SELECTED_FOLDER,
	SELECT_TOGGLE_SIDEBAR,
	SELECT_COLLAPSE_SIDEBAR,
	SET_SELECTED_AGENT,
	SET_SELECTED_TAGS,
	SET_MOBILE_VIEW,
	SET_ERRAND_MOBILE_VIEW,
	SET_WORKFLOW_READY,
	UPDATE_WF_PARAMS,
	SET_LIST_READY,
	TOGGLE_WF_POPUP,
	RESET_ERRAND_VIEW,
	CONTROL_ERRAND_MULTIPLE_ACTION,
	XFER_DOMAIN_ERRAND_PREVIEW,
	XFER_DOMAIN_WF_EXPERT_QUERIES,
	SET_FILTERED_TAGS,
	SET_FILTERED_AREAS,
	SET_VIEW_SINGLE_ERRAND_ONLY,
	SET_POST_MESSAGE_CHAT,
	RESET_WORKFLOW_FILTER,
	SHOW_WORKFLOW_MULTIPLE_ACTIONS,
	CHANGE_ERRAND_LIST_VIEW_MODE,
	SET_ERRAND_MESSAGE_TRUNCATE,
	SET_WFSETTINGS_FROM_LOCAL,
	SET_AGENTDATA_FROM_LOCAL,
	PM_SUBCRIBE_ERRAND_COUNT,
	SHOW_ALL_AREA_IN_AREA_FILTER,
	ACQUIRE_ERRAND_OPERATION,
	TOGGLE_ERRAND_FAV_FORWARD_TO_AREA,
	TOGGLE_ERRAND_FORWARD_TO_AREA,
	SELECT_ALL_MY_ERRAND,
	SELECT_LAUNCHPAD_LAYOUT,
	UPDATE_LAUNCHPAD_WIDGET,
	UPDATE_LAUNCHPAD_GRID_LAYOUT,
	ADD_LAUNCHPAD_WIDGET,
	DELETE_LAUNCHPAD_WIDGET
} from '../constants/constants';
import {
	CTX_MY,
	CTX_NEW,
	CTX_REVIEW,
	D_BASIC_ERRANDS,
	D_FORWARD_AGENTS,
	D_TAGS_INFO,
	MP_NONE,
	UI_SHOW_MANUAL_ERRAND,
	UI_SHOW_MANUAL_CALL,
	UI_SHOW_OLD_CHAT,
	UI_SHOW_BULK_SEND_POPUP,
	WFP_MANUAL_ERRAND,
	WFP_BULK_SEND_ERRAND,
	WFP_OLD_CHAT,
	PREF_LIST_VIEW,
	PREF_CONVERSATION_VIEW,
	emptyObject,
	MY_ERRANDS,
	NEW_ERRANDS,
	WFP_MANUAL_CALL,
	CTX_POSTPONED
} from '../../common/v5/constants';
import { ROUTE_MATCHER } from '../../common/v5/reviewConstants';
import { allAreaIDsFromOrgAreas } from '../../common/helpers';
import {
	getExtQueueType
	, getUniqueID
} from '../../common/v5/helpers';
import {
	storeWfLimitedSettingsValues
} from '../../common/v5/indexeddb';
import {
	createReducer,
	createRootUpdater,
	defAsyncReducer,
	defMCAMReducer,
	defRequestReducer,
	defStartReducer,
	defFailReducer,
	domainTransferReducer,
	asyncInitState,
	initWithOpr,
	mergeNormalizedDomainData,
	selectOprDataAsync,
	updateSelectAll,
	checkIfAllSelected,
	asyncRequestActionType,
	asyncDoneActionType,
	mcamCountersReducer,
	getStateName,
	mcamByID,
	addNormalizedDomain,
	isMobile
} from '../util';
import {
	errandListChat,
	chatForwardArea,
	chatForwardAgent,
} from './agentsocket';
import { errand as errandMap } from '../actions/async/errand';
import { errandList, workflow as workflowMap } from '../actions/async/workflow';
import { arrayAreasToAreaObjects } from '../selectors/common';
import { postponeAreaMap, reviewAreaMap } from '../selectors/review';
import {
	contextMemo
	, getSelectedFolderFilter
	, getWorkflowRoot
	, reducerMap
	, vipTagsSelector
} from '../selectors/workflow';
import { norm } from "@tensorflow/tfjs";

const stateName = key => getStateName(workflowMap[key]);

export const done = key => asyncDoneActionType(workflowMap[key]);

const req = key => asyncRequestActionType(workflowMap[key]);

const errandDone = key => asyncDoneActionType(errandMap[key]);

const fetchWfSettings = () => defAsyncReducer(keyErrandWorkflowSettings,
	asyncInitState);

const areas = () => defAsyncReducer(keyAreas, asyncInitState);
const organizationOverviewAreas = () => defAsyncReducer(keyOrganizationOverviewAreas, asyncInitState);

const selectAreas = (st, act) => {
	if(act.type !== SELECT_AREA_IN_AREA_LIST) {
		return st;
	}
	const {id, select} = act.payload;
	return selectOprDataAsync(st, id, select);
};

function selectAllAreas(st, act) {
	if(act.type === SELECT_AREA_IN_AREA_LIST) {
		return checkIfAllSelected(st);
	} else if(act.type === SELECT_ALL_AREAS) {
		return updateSelectAll(st, act.payload);
	}
	return st;
}

const fetchAreaErrandCount = () => defAsyncReducer(
	keyErrandFetchAreaErrandCount, asyncInitState);

const channels = () => defAsyncReducer(keyChannels, asyncInitState);

const agentAreas = () => defAsyncReducer(keyAgentAreas, asyncInitState);
const agentTemplates = () => defAsyncReducer(keyAgentTemplates, asyncInitState);

const agents = () => defAsyncReducer(keyAgents, asyncInitState);

const folders = () => defAsyncReducer(keyFolders, asyncInitState);

const agentGroups = () => defAsyncReducer(keyAgentGroups, asyncInitState);
const knowledgeBaseList = () => defAsyncReducer(keyGetKnowledgeBaseList, asyncInitState);

const agentStatistics = () => defMCAMReducer(keyErrandAgentStatistics,
	asyncInitState, 'getAgentStatistics');

const adminTagSimpleList = () => defAsyncReducer(keyAdminTagSimpleList,
	asyncInitState);

const adminTagList = () => defAsyncReducer(keyAdminTagList,
	asyncInitState);

const getSlaTime = () => defAsyncReducer(keyGetSlaTime, asyncInitState);

const connectedAgentAreas = () => defAsyncReducer(keyConnectedAgentAreas, asyncInitState);

const statisticsAgentAreas = () => defAsyncReducer(keyStatisticsAgentAreas, asyncInitState);
const createManualErrand = () => defAsyncReducer(keyCreateManualErrand, asyncInitState);

const incomingEmailsList = () => defAsyncReducer(keyIncomingEmailErrands, asyncInitState);
const allActiveEmailsErrands = () => defAsyncReducer(keyAllActiveErrands, asyncInitState);
const allActiveAgents = () => defAsyncReducer(keyAllActiveAgents, asyncInitState);

const allForwardedErrands = () => defAsyncReducer(keyFetchForwardedErrands, asyncInitState);
const allIncomingCollabs = () => defAsyncReducer(keyFetchIncomingCollaborations, asyncInitState);
const allDueErrandsInAWeek = () => defAsyncReducer(keyFetchDueErrandsInAWeek, asyncInitState);
const allExpiringErrands = () => defAsyncReducer(keyFetchExpiringErrands, asyncInitState);
const removeLaunchpadWidget = () => defAsyncReducer(keyDeleteLaunchpadWidgets, asyncInitState);
const addLaunchpadWidget = () => defAsyncReducer(keyAddLaunchpadWidgets, asyncInitState);
const basicQueuedErrand = () => defAsyncReducer(keyQueueBasicErrandInfo, asyncInitState);

function convertErrand(d) {
	let errand = {}
		, areaName = d.areaName
		, subject = d.iconicSubject
		;
	// TODO: remove this as likely no need reduce area name as it should be done
	// with CSS ellipsis.
	if ($(window).width() < 1024) {
		if (areaName.length > 20) {
			areaName = areaName.substr(0, 20) + '..';
		}
	}
	// TODO: remove this subject manipulation and do it at React component or
	// css way.
	if (/\>/.test(subject)) {
		const context = subject.split(/\>/);
		let newSubject = context[0] + ">";
		if (context.length > 1) {
			if (context[1].length > 50) {
				newSubject += context[1].substr(0, 50) + '...';
				subject = newSubject;
			}
		}
	} else {
		if (subject.length > 50) {
			subject = subject.substr(0, 50) + '...';
		}
	}
	errand.id = d.id;
	errand.handleId = d.id;
	//Note(mujib): v is undefined causing the UI broke, v is nowhere!!!
	//Make an extra guard to overcome it, however, later need to find the root.
	if (typeof v !== 'undefined' && v.hasChild != true && v.groupWith != 0) {
		errand.handleId = v.groupWith;
		errand.style = v.style;
		errand.groupWith = v.groupWith;
		errand.threadId = v.threadId;
		errand.hasChild = v.hasChild;
	}
	errand.displayId = d.displayId;
	errand.status = d.status;
	errand.subject = subject;
	errand.body = d.body;
	errand.agent = d.agent;
	errand.organisationName = d.organisationName;
	errand.areaName = areaName;
	errand.areaId = d.area;
	errand.from = d.from;
	errand.date = d.date;
	errand.service = d.service;
	errand.serviceName = d.serviceName;
	errand.fromAddress = d.fromAddress;
	errand.fromName = d.fromName;
	errand.donedate =  d.donedate;
	errand.postponedate =  d.postponedate;
	errand.answeredDate = d.answeredDate;
	errand.attachments = d.attachments;
	errand.priority = d.priority;
	
	errand.collaboration = d.collaboration;
	return errand;
}

const parseErrandList = (contents, res, currCtx, preOpr) => {
	let list = [];
	// TODO: check with coming from same ctx (page workflow hash), most
	// likely no need this as different object store for different ctx
	// New, My, Search, etc.
	if(!contents){
		//NOTE: Undefined , most probably logged out on another tab
		//Should it redirected to login page?
		return false;
	}
	if (contents.base) {
		if (contents.base.source === currCtx) {
			res.rqFromSameCtx = true;
		}
		res.offset = contents.base.offset;
	}
	if (contents.errands != undefined) {
		const rawOrder = contents.errands.order
			, rawList = contents.errands.list.slice()
			;
		let orderedResult = []
			, pinnedOrder = []
			, unpinnedOrder = []
			, norm = {}
			, opr = {}
			, groupCount = {}
			;
		res.errandRawSrcList = contents.errands;
		$.each(rawOrder, (i, v) => {
			$.each(rawList, (j, w) => {
				if (w.id == v) {
					// Sorted out pinned errands and non-pinned errands
					if (w.data.pinToTop) {
						pinnedOrder.push(v);
					} else {
						unpinnedOrder.push(v);
					}
					orderedResult.push(w);
					norm[v] = w;
					if (!groupCount[w.threadId]) {
						groupCount[w.threadId] = 1;
					} else {
						groupCount[w.threadId]++;
					}
					const o = preOpr[v];
					if (o === undefined) {
						opr[v] = {selected: false};
					} else {
						opr[v] = o;
					}
					list.push(convertErrand(w.data));
					rawList.splice(j, 1);
					return false;
				}
			});
		});
		res.order = [...pinnedOrder, ...unpinnedOrder];
		res.norm = norm;
		res.opr = opr;
		res.groupCount = groupCount;
	}
	let count = 0
		, upperCount = 0
		, vipCount = 0
		;
	if (contents.statistics) {
		// get total errand count
		if (typeof contents.statistics.max !== 'undefined') {
			count = contents.statistics.max;
		}
		// get total upper count
		if (typeof contents.statistics.upper !== 'undefined') {
			upperCount = contents.statistics.upper;
		}
		// get total vip count
		if (typeof contents.statistics.vips !== 'undefined') {
			vipCount = contents.statistics.vips;
		}
	}
	res.totalErrandCount = count;
	res.totalUpperCount = upperCount;
	res.totalVIPCount = vipCount;
	// get total grouped errands
	if (contents.totalGroupErrands !== 0) {
		count = contents.totalGroupErrands;
		res.hasTotalGroupedErrands = true;
	}
	res.totalGroupedErrands = count;
	res.counters = contents.counters;
	res.list = list; // TODO: to be removed
	return res;
};

function errandListProcessor(mcamID, data, context, preOpr) {
	return parseErrandList(mcamByID(mcamID, data), {}, context, preOpr);
}

const memoizedErrandListProcessor = memoizeOne(errandListProcessor);

const partialErrandList = () => defAsyncReducer(keyErrandList, initWithOpr,
	(state, action) => {
		const { data } = action.payload;
		let {
				order
				, norm
				, opr
				, totalErrandCount
				, totalUpperCount
				, totalVIPCount
				, totalGroupedErrands
				, hasTotalGroupedErrands
				, groupCount
				, counters
			} = memoizedErrandListProcessor(
				'fetchErrandList'
				, data
				, state.param.context
				, state.data.opr
			)
			, pinnedErrandIds = []
			, unpinnedErrandIds = []
			;
		let byAgentsAreasDefaultFolders
			, byAgentsAreasFoldersTags
			;
		if (counters) {
			byAgentsAreasDefaultFolders = counters.byAgentsAreasDefaultFolders;
			byAgentsAreasFoldersTags = counters.byAgentsAreasFoldersTags;
		}
		return update(state, {data: {$merge: {
			allSelected: false // mak: TODO remove
			, order
			, opr
			, norm
			, totalErrandCount
			, totalUpperCount
			, totalVIPCount
			, totalGroupedErrands
			, hasTotalGroupedErrands
			, groupCount
			, counters
			, counterAgents: getUniqueID(byAgentsAreasDefaultFolders, 0)
			, counterAreas: getUniqueID(byAgentsAreasDefaultFolders, 1)
			, counterTags: getUniqueID(byAgentsAreasFoldersTags, 3)
		}}});
	}
);

const updateBasicOfErrandListSubReducer = (state, action) => {
	if (!state.data || !state.data.norm) {
		return state;
	}
	const { data } = action.payload;
	if (!data || !data.id) {
		return state;
	}
	const errand = state.data.norm[data.id];
	if (!errand) {
		return state;
	}
	const { groupWith, hasChild } = errand
		// NOTE: it is assumed that no operation can change the groupWith and
		// hasChild except the errand list itself. These properties require
		// errand list data. Any single basic data reload can never able to know
		// the value of these properties.
		, updatedData = update(data, {
			groupWith: {$set: groupWith}
			, hasChild: {$set: hasChild}
		})
		;
	return update(state, {data: {norm: {[data.id]: {$set: updatedData}}}});
};

const updateSingleErrandReducer = (state, action) => {
	const { type } = action;
	if (type === errandDone(keyLoadBasicErrand) ||
		type === errandDone(keyGetOneRelatedErrand) ||
		type === errandDone(keyReloadOneErrand)) {
		return updateBasicOfErrandListSubReducer(state, action);
	}
	return state;
};

const updateSingleErrandBySingleDataReducer = (state, action) => {
	const { type } = action;
	if (type === errandDone(keyErrandUpdateLockToMe) ){
		return updateBasicDataOneFieldSubReducer(state, action);
	} else if (type === errandDone(keyFetchExternalExpertThread)) {
		const p = action.payload;
		if (p.data && typeof p.data.lightOn !== "undefined") {
			return updateBasicDataLightCollabSubReducer(state, action, {
				id: p.param.errand,
				light: p.data.lightOn
			});
		}
	}
	return state;
}

const updateBasicDataOneFieldSubReducer = (state, action) => {
	if (!state.data || !state.data.norm) {
		return state;
	}
	const { data } = action.payload;
	if (!data || !data.data) {
		return state;
	}

	let context = state.param.context;
	if(context === CTX_MY){
		let updatedData = data.data;
		//given payload is like {data: { id: N, field: XXX }}
		//same structure as basic data
		//to overwrite existing basic data
		return update(state, {data: {norm: {[updatedData.id]: {
			data: {
				$merge: updatedData
			}
		}}}});
	}
	return state;
};

// TODO: collaboration light is associated with errand thread and not just
// errand. Another word it can happens two or more errands pointing to the same
// light status because of same thread. This reducer only sync part of it if
// those errands are all within the redux-store.
const updateBasicDataLightCollabSubReducer = (state, action, { id, light }) => {
	if (!state.data ||
		!state.data.norm ||
		!state.data.norm[id] ||
		state.param.context !== CTX_MY) {
		return state;
	}
	return update(state, {
		data: {
			norm: {
				[id]: {
					data: {
						collaboration: {
							light: { $set: light }
						}
					}
				}
			}
		}
	});
}

export const syncOneErrandOnNormalizedList = (state, action) => {
	if (action.type !== SYNC_ALL_BASIC_ERRAND_DATA) {
		return state;
	}
	return updateBasicOfErrandListSubReducer(state, action);
};

const syncRelatedErrandsNormalizedSubReducer = (state, action, getter, updater) => {
	if (action.type !== SYNC_ACQUIRED_STATE) {
		return state;
	}
	const norm = getter(state);
	if (!norm) {
		return state;
	}
	const { errandIDs, data } = action.payload;
	let updateNorm;
	$.each(errandIDs, (i, v) => {
		if (!norm[v]) {
			return;
		}
		if (!updateNorm) {
			updateNorm = {};
		}
		updateNorm[v] = data;
	});
	if (!updateNorm) {
		return state;
	}
	return update(state, updater(updateNorm));
};

export const syncRelatedErrandsNormalized = (state, action) =>
	syncRelatedErrandsNormalizedSubReducer(
		state
		, action
		, state => {
			if (!state.data || !state.data.norm) {
				return;
			}
			return state.data.norm;
		}
		, data => ({data: {norm: data}})
	);

function selectErrandList(st, act) {
	if(act.type !== SELECT_ERRAND_IN_ERRAND_LIST) {
		return st;
	}
	const {id, select} = act.payload;
	return selectOprDataAsync(st, id, select);
}

function selectAllErrands(st, act) {
	if(act.type === SELECT_ALL_ERRANDS_IN_ERRAND_LIST || act.type === SELECT_ALL_ERRANDS_WITHOUT_CHAT) {
		return updateSelectAll(st, act.payload);
	}
	return st;
}

function openErrandReducer (state, action) {
	if (action.type !== SET_CURRENT_ERRAND) {
		return state;
	}
	const { id } = action.payload;
	if (id <= 0) {
		return state;
	}
	let newState = updateSelectAll(state, false);
	newState = selectOprDataAsync(newState, id, true);
	return newState;
}

// TODO: remove this as we don't use cache for errand list
const domainErrandListContextReducer = (state, action) => update(state, {
	currentErrandList: {context: {$set: action.payload.param.context}}});

const domainErrandList = (state, action) => {
	const { data, time } = action.payload, ce = state.currentErrandList;
	if(data) {
		const prevData = ce, opr = {}, {
			order,
			norm,
			totalErrandCount,
			totalUpperCount,
			totalGroupedErrands
		} = memoizedErrandListProcessor(
			'fetchErrandList'
			, data
			, ce.context
			, prevData.opr
		)
		;
		if(norm && order && order.length) {
			$.each(order, function(i,v) {
				const o = prevData.opr[v];
				if(o === undefined) {
					opr[v] = {selected: false};
				} else {
					opr[v] = o;
				}
			});
			$.each(norm, (id,v) => {
				state = addNormalizedDomain(state, D_BASIC_ERRANDS, v.id, v,
					time);
			});
			 // TODO: redundant temporary can be removed
			return update(state, {currentErrandList: {$merge: {
				allSelected: false, // mak: TODO remove
				opr,
				order,
				norm,
				totalErrandCount,
				totalUpperCount,
				totalGroupedErrands
			}}});
		}
	}
	return state;
};

const getErrands = () => defAsyncReducer(keyGetErrands, asyncInitState);

const deleteErrands = () => defAsyncReducer(keyDeleteErrands, asyncInitState,
	(state, action) =>{
		const { data } = action.payload
			, delErrand = mcamByID('DeleteErrands', data);
		if(delErrand.error){
			return update(state, {err: {$set: new Error(delErrand.error)}});
		}
	}
);

const closeErrand = () => defAsyncReducer(keyCloseErrand, asyncInitState);

const closeErrands = () => defAsyncReducer(keyCloseErrands, asyncInitState,
	(state, action) => {
		const {data} = action.payload
			, retMsg = mcamByID('CloseErrands', data);
		if(retMsg.error){
			return update(state, {err: {$set: new Error(retMsg.error)}});
		}
	}
);

const sendBulkErrands = () => defAsyncReducer(keyBulkSendErrands, asyncInitState);

const uploadAnswerAttachment = () => defAsyncReducer(
	keyErrandUploadAnswerAttachment, asyncInitState);

const removeTemporaryAttachment = () => defAsyncReducer(
	keyErrandRemoveTemporaryAttachment, asyncInitState);

const queueToMe = () => defAsyncReducer(keyQueueToMe, asyncInitState);

const forwardToArea = () => defAsyncReducer(keyForwardToArea, asyncInitState,
	(state, action) => {
		const {data} = action.payload
			, retMsg = mcamByID('ChangeErrandArea', data);
		if(retMsg.error){
			return update(state, {err: {$set: new Error(retMsg.error)}});
		}
	}
);

const moveToFolder = () => defAsyncReducer(keyMoveToFolder, asyncInitState);

const forwardToAgent = () => defMCAMReducer(
	keyForwardToAgent
	, asyncInitState
	, 'ChangeErrandAgent'
);

const returnToInbox = () => defAsyncReducer(keyRetunToInbox, asyncInitState);

const linkErrand = () => defAsyncReducer(keyLinkErrand, asyncInitState);

const agentSetErrandView = () => defAsyncReducer(keyAgentSetErrandView, asyncInitState);

const commentCountInitState = {
	new: {count: 0, colour: '#DC80000'},
	my: {count: 0, colour: '#DC80000'}
};

const commentCountReducer = (st, act) => {
	if(act.type === done(keyErrandList)) {
		const commentCount = {
			new: st.errandList.data.newErrandCount,
			my: st.errandList.data.myErrandCount
		}, state = update(st, {$merge: {commentCount}});
		return update(state, {errandList: {data: {$unset: ['newErrandCount',
			'myErrandCount']}}});
	}
	return st;
};

const setDefaultAreaIds = (areas) => {
	var areaList = [];
	if(areas){
		areas.forEach(function( d ){
			if(d.Areas != undefined){
				d.Areas.forEach(function( a ){
					areaList.push(a.Id);
				}.bind(this));
			}
		}.bind(this));
	}
	return areaList;
}

const listInputsReducer = (st = listInputsInitState, act) => {
	if(act.type === UPDATE_WF_PARAMS) {
		const p = act.payload;
		if(p) {
			return update(st, {listInputs: {$merge: p}});
		}
	}
	if(act.type === CHANGE_CONTEXT){
		const p = act.payload;
		if(p.value === CTX_NEW){ //NEW_ERRANDS
			return update(st, {
				listInputs: {
					offset: {$set: 0},
					source: {$set: p.value},
					folder: {$set: 0}
				}}
			);
		}else{
			return update(st, {
				listInputs: {
					offset: {$set: 0},
					source: {$set: p.value}
				}}
			);
		}
	}
	if(act.type === SET_SELECTED_TAGS){
		let updatedTags = st.filter.selectedTags.join(",");
		return update(st, {listInputs: {classification: {$set: updatedTags}, offset: {$set: 0}}});
	}
	return st;
};

const updateWorkflowSettingsReducer = (st, act) => {
	const p = act.payload;
	if(act.type === done(keyAgentSetErrandView)) {
		if(p.data) {
			if(p.data.preferErrandView){
				return update(st, {fetchWfSettings: {
					data: {
							agentErrandListViewPref: {$set: p.data.preferErrandView},
						}
					}
				});
			}
			if(typeof p.data.verticalView === 'boolean'){
				return update(st, {fetchWfSettings: {
					data: {
							verticalView: {$set: p.data.verticalView}
						}
					}
				});
			}
			if(typeof p.data.toggleForwardToAreaFavourites === 'boolean'){
				return update(st, {fetchWfSettings: {
					data: {
							toggleForwardToAreaFavourites: {$set: p.data.toggleForwardToAreaFavourites}
						}
					}
				});
			}
			if(typeof p.data.toggleForwardToArea === 'boolean'){
				return update(st, {fetchWfSettings: {
					data: {
							toggleForwardToArea: {$set: p.data.toggleForwardToArea}
						}
					}
				});
			}
			if(typeof p.data.showErrandHeader === 'boolean'){
				return update(st, {fetchWfSettings: {
					data: {
							showErrandHeader: {$set: p.data.showErrandHeader}
						}
					}
				});
			}
			if(typeof p.data.searchSortDirection != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							searchSortDirection: {$set: p.data.searchSortDirection},
						}
					}
				});
			}
			if(typeof p.data.searchSortAttribute != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							searchSortAttribute: {$set: p.data.searchSortAttribute},
						}
					}
				});
			}
			if(typeof p.data.showReplyPanel != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							showReplyPanel: {$set: p.data.showReplyPanel},
						}
					}
				});
			}
			if(typeof p.data.sideBarHidden != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							sideBarHidden: {$set: p.data.sideBarHidden}
						}
					}
				});
			}
			if(typeof p.data.sideBarCollapse != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							sideBarCollapse: {$set: p.data.sideBarCollapse}
						}
					}
				});
			}
			if(typeof p.data.showAgentAssistPanel != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							showAgentAssistPanel: {$set: p.data.showAgentAssistPanel}
						}
					}
				});
			}
			if(typeof p.data.launchpadLayout != "undefined") {
				return update(st, {fetchWfSettings: {
					data: {
							launchpadLayout: {$set: p.data.launchpadLayout}
						}
					}
				});
			}
			if(typeof p.data.launchpadWidgets != "undefined"){
				return update(st, {fetchWfSettings: {
					data: {
							launchpadWidgets: {$set: p.data.launchpadWidgets}
						}
					}
				});
			}
			if(typeof p.data.launchpadGridLayout != "undefined"){
				return update(st, {fetchWfSettings: {
					data: {
							launchpadGridLayout: {$set: p.data.launchpadGridLayout}
						}
					}
				});
			}
		}
	}
	return st;
}

const initUI = {
	showErrandListSort: false,
	// TODO: this default state is tangling as no such selection 'id' for
	// SelectBox to select https://github.com/cention/cention-applications/blob/8760818934698a59e4a5c37c2791e4c6493586e0/web/app/components/v5/ErrandList.js#L23-L26
	errandListSort: 0,
	showPriorityFilter: false,
	showAreasFilter: false,
	showAgentsFilter: false,
	showTagsFilter: false,
	showAllArea:  false,
	// 'showSideBar' is supposely has opposite initiated state on mobile
	// false by default
	// true when workflow side bar clicked and wf menu dropped.
	showSideBar: isMobile ? false : true,
	collapseSideBar: false,
	launchpadLayout: 1, //only 3 options for now, see Launchpad component
	launchpadWidgets: [],
	launchpadGridLayout: "",
	showMobileView: isMobile ? true : false,
	showErrandMobileView: false,
	showErrandHeaderMobileView: false,
	listReady: false,
	[UI_SHOW_MANUAL_ERRAND]: MP_NONE, // use selector to check if manual errand popup
	[UI_SHOW_MANUAL_CALL]: MP_NONE,
	[UI_SHOW_OLD_CHAT]: false,
	[UI_SHOW_BULK_SEND_POPUP]: false,
	showMoveToFolder: false,
	showForwardAreaOptions: false,
	currentPreviewId: 0,
	viewSingleErrandOnly: false,
	postMessageChat: false,
	showMultipleActions: false,
	changingContext: false,
	subscribeErrandCountPM: [],
	errandListViewMode: PREF_CONVERSATION_VIEW,
	errandMessageTruncateByDefault: false,
	toggleForwardToAreaFavourites: false,
	toggleForwardToArea: true,
	windowInnerHeight: "100vh",
	queuedErrands: [] //to store (by Id, and qType, and direction) the errands that are queued to me (after queue-errand-event ) and it's info need to be fetch to be included in the errand list
}

//TODO: Cleanup unused ui filter
const initFilter = {
	// NOTE: NEVER NEVER ever direct access this current value. NOT EVEN
	// reducer.
	currentContext: CTX_MY, // TODO: this is redundant state to listInputs.source
	myErrandFolder: 0,
	selectedAreas: {
		[CTX_MY]: emptyObject,
		[CTX_NEW]: emptyObject,
		[CTX_REVIEW]: emptyObject,
		[CTX_POSTPONED]: emptyObject
	},
	selectedFolder: 0,
	selectedAgent: 0,
	selectedTags: [],
	filteredTags: [],
	filteredAreas: [],
	resetErrandView: false
};

const uiLocalReducer = (st = initUI, act) => {
	const p = act.payload;
	if(act.type === SELECT_TOGGLE_SORT_ERRAND_LIST) {
		if(typeof p.value === 'boolean') {
			return update(st, {showErrandListSort: {$set: p.value}});
		}
		return update(st, {$merge: {showErrandListSort: false,
			errandListSort: p.index}});
	}
	else if(act.type == SELECT_TOGGLE_PRIORITY_FILTER) {
		return update(st, {showPriorityFilter: {$set: !st.showPriorityFilter}})
	}else if(act.type == SELECT_TOGGLE_AREAS_FILTER) {
		return update(st, {showAreasFilter: {$set: !st.showAreasFilter}})
	}else if(act.type == SELECT_TOGGLE_AGENTS_FILTER) {
		return update(st, {showAgentsFilter: {$set: !st.showAgentsFilter}})
	}else if(act.type == SELECT_TOGGLE_TAGS_FILTER) {
		return update(st, {showTagsFilter: {$set: !st.showTagsFilter}})
	}else if(act.type == SELECT_TOGGLE_SIDEBAR) {
		return update(st, {showSideBar: {$set: p.value}});
	}else if(act.type == SELECT_COLLAPSE_SIDEBAR) {
		return update(st, {collapseSideBar: {$set: p.value}});
	}else if(act.type == SELECT_LAUNCHPAD_LAYOUT) {
		return update(st, {launchpadLayout: {$set: p.value}});
	}else if(act.type == UPDATE_LAUNCHPAD_WIDGET){
		return update(st, {launchpadWidgets: {$set: p.value}});
	}else if(act.type == UPDATE_LAUNCHPAD_GRID_LAYOUT){
		return update(st, {
			launchpadGridLayout: {$set: p.launchpadGridLayout},
			launchpadLayout: {$set: p.launchpadLayout}
		});
	}else if(act.type == ADD_LAUNCHPAD_WIDGET) {
		return update(st, {launchpadWidgets: {$push: [p.value]}});
	}else if(act.type == DELETE_LAUNCHPAD_WIDGET) {
		const id = p.value;
		let launchpadWidgets = st.launchpadWidgets;
		let index = launchpadWidgets.findIndex((widget) => widget.id == id);
		if(index > -1){
			launchpadWidgets.splice(index, 1);
		}
		return update(st, {launchpadWidgets: {$set: launchpadWidgets}});
	}else if(act.type == SET_MOBILE_VIEW) {
		return update(st, {
			showMobileView: {$set: p.value},
			windowInnerHeight: {$set: p.height}
		});
	}else if(act.type == SET_ERRAND_MOBILE_VIEW) {
		return update(st, {showErrandMobileView: {$set: p.value}})
	}else if(act.type == PM_SUBCRIBE_ERRAND_COUNT) {
		return update(st, {subscribeErrandCountPM: {$set: p.value}})
	}else if(act.type == SET_LIST_READY) {
		return update(st, {listReady: {$set: p.value}})
	} else if(act.type == TOGGLE_WF_POPUP) {
		const {which, value} = p;
		let showWhich;
		switch(which) {
			case WFP_MANUAL_ERRAND:
				showWhich = UI_SHOW_MANUAL_ERRAND;
				break;
			case WFP_MANUAL_CALL:
				showWhich = UI_SHOW_MANUAL_CALL;
				break;
			case WFP_OLD_CHAT:
				showWhich = UI_SHOW_OLD_CHAT;
				break;
			case WFP_BULK_SEND_ERRAND:
				showWhich = UI_SHOW_BULK_SEND_POPUP;
				break;
		}
		if (showWhich) {
			return update(st, {[showWhich]: {$set: value}});
		} else {
			return st;
		}
	} else if(act.type == CONTROL_ERRAND_MULTIPLE_ACTION){
		let p = act.payload;
		if(p.actionFor === 'moveFolder'){
			return update(st, {showMoveToFolder: {$set: p.show}});
		}else if(p.actionFor === 'forwardArea'){
			return update(st, {showForwardAreaOptions: {$set: p.show}});
		}
	} else if(act.type == XFER_DOMAIN_ERRAND_PREVIEW) {
		//FIXME: Don't call this when preview preference is off
		//better not call ErrandPreview api at all when it disable
		return update(st, {currentPreviewId: {$set: act.payload.id}});
	} else if(act.type == SET_VIEW_SINGLE_ERRAND_ONLY) {
			return update(st, {viewSingleErrandOnly: { $set: act.payload.value}});
	} else if(act.type == SET_POST_MESSAGE_CHAT) {
			return update(st, {postMessageChat: { $set: act.payload.value}});
	} else if(act.type == SHOW_WORKFLOW_MULTIPLE_ACTIONS) {
		return update(st, {showMultipleActions: {$set: act.payload}});
	} else if(act.type === CHANGE_ERRAND_LIST_VIEW_MODE) {
		return update(st, {errandListViewMode: {$set: act.payload}});
	} else if(act.type === TOGGLE_ERRAND_FAV_FORWARD_TO_AREA) {
		return update(st, {toggleForwardToAreaFavourites: {$set: act.payload}});
	} else if(act.type === TOGGLE_ERRAND_FORWARD_TO_AREA) {
		return update(st, {toggleForwardToArea: {$set: act.payload}});
	} else if(act.type === SET_ERRAND_MESSAGE_TRUNCATE) {
		return update(st, {errandMessageTruncateByDefault: {$set: act.payload}});
	}else if(act.type == SHOW_ALL_AREA_IN_AREA_FILTER) {
		return update(st, {showAllArea: {$set: !st.showAllArea}})
	}else if (act.type == "QUEUE_ERRAND_EVENT") {
		let payload = act.payload;
		
		// Initialize queuedErrands with the current state's queuedErrands, if any
		let queuedErrands = [];
		if(payload.channel !== "chat"){
		payload.events.forEach((event) => {
			
			let newErrand = {
				id: payload.errand_id,
				qType: event.queue_type,
				direction: event.direction
			};
			queuedErrands.push(newErrand);
		});
	
		return update(st, { queuedErrands: { $set: queuedErrands } });
	}
}
	return st;
	
	
};

const uiLocalFilter = (st = initFilter, act) => {
	const p = act.payload;
	if(act.type === CHANGE_CONTEXT) {
		return update(st, {
			currentContext: {$set: p.value},
			selectedAgent: {$set: 0},
			contextChanged: {$set: (st.currentContext !== p.value) ? true : false}
		});
	}else if(act.type === SET_MY_ERRANDS_FOLDER) {
		return update(st, {myErrandFolder: {$set: p.value}})
	}else if(act.type === SET_SELECTED_FOLDER) {
		return update(st, {selectedFolder: {$set: p.value}})
	}else if(act.type === SET_SELECTED_AGENT) {
		return update(st, {
			selectedAgent: {$set: p.value},
			selectedTags: {$set: []}
		});
	}else if(act.type === SET_SELECTED_TAGS) {
		let index = st.selectedTags.indexOf(p.value);
		if(!p.addAction && index > -1) {
			return update(st, {
				selectedTags: {$splice: [[index, 1]]}
			});
		}else{
			if(index === -1){
				return update(st, {
					selectedTags: {$push: [p.value]}
				});
			}
		}
	}else if(act.type === RESET_ERRAND_VIEW) {
		return update(st, {resetErrandView: {$set: p}});
	}else if(act.type === SET_FILTERED_TAGS) {
		return update(st, {
			filteredTags: {$set: p}
		});
	}else if(act.type === SET_FILTERED_AREAS) {
		return update(st, {
			filteredAreas: {$set: p}
		});
	}else if(act.type === RESET_WORKFLOW_FILTER) {
		return update(st, {
			selectedFolder: {$set: 0},
			selectedAgent: {$set: 0},
			selectedTags: {$set: []},
			filteredTags: {$set: []},
		});
	}else if (act.type === CHANGE_SELECTED_FILTER_AREAS) {
		const { context, value } = act.payload;
		return update(st, {selectedAreas: {[context]: {$set: value}}});
	}
	return st;
}

const _filterSubReducers = {
	[SET_LIST_READY]: (state, action) => {
		if (!action.payload.value) {
			return state;
		}
		// TODO: remove all those previously selected filter that no longer
		// apply.
		return state;
	}
	, ...reducerMap
};

const filterReducer = createReducer({}, _filterSubReducers);

const setupAgentDefaultPreference = (state, action) => {
	const wf = state.fetchWfSettings.data, folders = wf.userFolders;
	let sort_direction;
	if(wf.sortDescendingByDefault) {
		sort_direction = 'desc';
	} else {
		sort_direction = 'asc';
	}
	let newList = {
		size: {$set: wf.agentInboxSize},
		sort_direction: {$set: sort_direction},
		user: {$set: wf.activeUserId}
	};
	for(let i=0; i<folders.length; i++) {
		const folder = folders[i];
		if(folder.value === CTX_MY) {
			newList.folder = {$set: folder.id};
			break;
		}
	}
	if(wf.preferredMyErrandsAreaID &&
		wf.preferredMyErrandsAreaID.length > 0) {
		newList.filterList = {$set: wf.preferredMyErrandsAreaID.join(',')};
	}
	let newState = {listInputs: newList};
	let ui = {
		errandListViewMode: {$set: PREF_LIST_VIEW},
		errandMessageTruncateByDefault: {$set: false},
		toggleForwardToAreaFavourites: {$set: wf.toggleForwardToAreaFavourites},
		toggleForwardToArea: {$set: wf.toggleForwardToArea},
		showSideBar: {$set: true},
		collapseSideBar: {$set: false},
		launchpadLayout: {$set: 1},
		launchpadGridLayout: {$set: ""},
	}
	if(wf.agentErrandListViewPref) {
		ui.errandListViewMode = {$set: wf.agentErrandListViewPref};
	}
	if(wf.truncateErrandMessage) {
		ui.errandMessageTruncateByDefault = {$set: wf.truncateErrandMessage};
	}
	if(wf.sideBarHidden) {
		ui.showSideBar = {$set: !wf.sideBarHidden};
	}
	if(!isMobile) {
		if(wf.sideBarCollapse){
			ui.collapseSideBar = {$set: wf.sideBarCollapse};
		}
	}else{
		ui.collapseSideBar = {$set: false};
	}
	if(wf.launchpadLayout) {
		ui.launchpadLayout = {$set: wf.launchpadLayout};
	}
	if(wf.launchpadWidgets) {
		ui.launchpadWidgets = {$set: wf.launchpadWidgets};
	}
	if(wf.launchpadGridLayout) {
		ui.launchpadGridLayout = {$set: wf.launchpadGridLayout};
	}
	newState = {listInputs: newList, ui: ui};
	return update(state, newState);
};

const setupAgentDefaultFilterAreaList = (state, action) => {
	const wf = state.fetchWfSettings.data
		, newAreas = arrayAreasToAreaObjects(wf.preferredNewErrandsAreaID)
		, myAreas = arrayAreasToAreaObjects(wf.preferredMyErrandsAreaID)
		, reviewAreas = reviewAreaMap(wf)
		, postponeAreas = arrayAreasToAreaObjects(wf.postponedAreaID)
		//postponeAreaMap(wf)
		;
	console.log('postponeAreas =', postponeAreas);
	if (newAreas === emptyObject
		&& myAreas === emptyObject
		&& reviewAreas === emptyObject
		&& postponeAreas === emptyObject) {
		return state;
	}
	return update(state, {filter: {selectedAreas: {
		[CTX_MY]: {$set: myAreas}
		, [CTX_NEW]: {$set: newAreas}
		, [CTX_REVIEW]: {$set: reviewAreas}
		, [CTX_POSTPONED]: {$set: postponeAreas}
	}}});
};

const setupDefaultWFSettings = (state, action) => {
	if (action.type === done(keyErrandWorkflowSettings)) {
		if(getExtQueueType() !== "" &&
			typeof action.payload.data !== 'undefined' &&
			action.payload.data != null){
			storeWfLimitedSettingsValues(action.payload.data);
		}
		return reduceReducers(
			setupAgentDefaultPreference
			, setupAgentDefaultFilterAreaList
		)(state, action);
	} else if(action.type === SET_WFSETTINGS_FROM_LOCAL){
		return update(state, {fetchWfSettings: { data:
			{$set: action.payload.data}}});
	}
	return state;
};

const setAgentDataFromLocalDb = (state, action) => {
	if(action.type === SET_AGENTDATA_FROM_LOCAL){
		const data = action.payload.data;
		return update(state,
			{agentAreas: {$set: data.agentAreas},
			agents: {$set: data.agents},
			channels: {$set: data.channels},
			adminTagList: {$set: data.adminTagList},
			agentGroups: {$set: data.agentGroups},
			knowledgeBaseList: {$set: data.knowledgeBaseList}
		});
	}
	return state;
}

const domainErrandPreview = (state, action) => {
	const { data, time } = action.payload;
	return addNormalizedDomain(state, 'previewData', data.id, data.body, time);
};

export const domainWFExpertQueries = (state, action) => {
	const { param, data, time } = action.payload;
	return addNormalizedDomain(state, 'expertQueries', param.errand, data,
		time);
};

export const domainWFClientAvatar = (state, action) => {
	const { param, data, time } = action.payload;
	$.each(data, (i,v) => {
		state = addNormalizedDomain(state, 'clientAvatar', v.id, v, time)
	});
    return state;
};

const domainSyncRelatedErrands = (state, action) =>
	syncRelatedErrandsNormalizedSubReducer(
		state
		, action
		, state => state[D_BASIC_ERRANDS].byId
		, data => ({[D_BASIC_ERRANDS]: {byId: data}})
	);

export const domain = {
	[done(keyDeleteErrands)]: mcamCountersReducer
	, [done(keyCloseErrand)]: mcamCountersReducer
	, [done(keyCloseErrands)]: mcamCountersReducer
	, [done(keyAreasAgentsData)]: (state, action) => {
		const { data, time } = action.payload;
		$.each(data, (k, { agents }) => {
			if (agents) {
				state = addNormalizedDomain(
					state
					, D_FORWARD_AGENTS
					, k
					, agents
					, time
				);
			}
		});
		return state;
	}
	, [req(keyErrandList)]: domainErrandListContextReducer // TODO: remove this
	, [done(keyErrandList)]: reduceReducers(
		mcamCountersReducer
		, domainErrandList
	)
	, [done(keyErrandAgentStatistics)]: mcamCountersReducer
	, [done(keyErrandPreview)]: domainErrandPreview
	, [done(keyRetunToInbox)]: mcamCountersReducer
	, [done(keyLinkErrand)]: mcamCountersReducer
	, [done(keyWFExpertQueries)]: domainWFExpertQueries
	, [done(keyGetClientAvatar)]: domainWFClientAvatar
	, [SYNC_ACQUIRED_STATE]: domainSyncRelatedErrands
	, [done(keyAdminTagNameAll)]: (state, { payload }) => mergeNormalizedDomainData(state, D_TAGS_INFO, payload.data, payload.time)
};

function getInitialSourceContext(path) {
	if (matchPath(path, ROUTE_MATCHER)) {
		return CTX_REVIEW;
	}
	return CTX_MY;
}

const listInputsInitState = {
	active_flag: 'no',
	box: 0,
	classification: '',
	date_to: '',
	date_from: '',
	date_type: '',
	deleted: 0,
	autoRefresh: false,
	exact_match: 'no',
	filterList: '',
	folder: 0,
	offset: 0,
	search: '',
	search_source: 0,
	search_type: 0,
	service: 0,
	sort_attribute: 'date',
	sort_name: I('Date'),
	sort_direction: 'desc',
	sort_expired_first: 'no',
	sort_hp_first: 'no',
	sort_replies_first: 'no',
	sort_warnings_first: 'no',
	sort_collaboration_first: 'no',
	sort_expired_first_newErrand: 'no',  // any *_newErrand only appear in
	sort_hp_first_newErrand: 'no',       //    front-end but no back-end code
	sort_replies_first_newErrand: 'no',  //    handle them as backend will check
	sort_warnings_first_newErrand: 'no', //    source for different context.
	sort_collaboration_first_newErrand: 'no',
	source: getInitialSourceContext(window.location.pathname),
	user: 0,
	origin: '',
	size: 17,
	isVIP: false
};

const sortInitState = {
	expired_first: 'no'
	, hp_first: 'no'
	, replies_first: 'no'
	, warnings_first: 'no'
	, collaboration_first: 'no'
};

const contextSortInitState = {
	[CTX_MY]: sortInitState
	, [CTX_NEW]: sortInitState
	, [CTX_REVIEW]: sortInitState
	, [CTX_POSTPONED]: sortInitState
};

let workflow = combineReducers({
	[stateName(keyAdminTagSimpleList)]: adminTagSimpleList(),
	[stateName(keyAdminTagList)]: adminTagList(),
	[stateName(keyAgents)]: reduceReducers(
		agents()
		, (state, action) => {
			if (action.type !== NORMALIZE_AGENTS || !state.data
				|| !state.data.agents) {
				return state;
			}
			const byId = {};
			$.each(state.data.agents, (i, v) => {
				byId[v.Id] = v;
			});
			return update(state, {byId: {$set: byId}});
		}
	),
	[stateName(keyFolders)]: reduceReducers(
		folders()
		, (state, action) => {
			if (!state.data || !state.data.folders) {
				return state;
			}
			const byId = {};
			$.each(state.data.folders, (i, v) => {
				byId[v.Id] = v;
			});
			return update(state, {byId: {$set: byId}});
		}
	),
	[stateName(keyAreas)]: areas(),
	[stateName(keyAreasAgentsData)]: defAsyncReducer(
		keyAreasAgentsData
		, asyncInitState
	),
	[stateName(keyOrganizationOverviewAreas)]: organizationOverviewAreas(),
	[stateName(keyAgentGroups)]: agentGroups(),
	[stateName(keyGetKnowledgeBaseList)]: knowledgeBaseList(),
	[stateName(keyAgentAreas)]: agentAreas(),
	[stateName(keyAgentTemplates)]: agentTemplates(),
	[stateName(keyChannels)]: channels(),
	[stateName(keyCloseErrand)]: closeErrand(),
	[stateName(keyCloseErrands)]: closeErrands(),
	[stateName(keyDeleteErrands)]: deleteErrands(),
	[stateName(keyBulkSendErrands)]: sendBulkErrands(),
	[stateName(keyErrandAgentStatistics)]: agentStatistics(),
	[stateName(keyErrandFetchAreaErrandCount)]: fetchAreaErrandCount(),
	[stateName(keyErrandList)]: reduceReducers(
		partialErrandList()
		, selectErrandList
		, selectAllErrands
		, openErrandReducer
		, updateSingleErrandReducer
		, updateSingleErrandBySingleDataReducer
		, syncOneErrandOnNormalizedList
		, syncRelatedErrandsNormalized
	),
	[stateName(keyErrandListChat)]: errandListChat,
	[stateName(keyChatForwardArea)]: chatForwardArea,
	[stateName(keyChatForwardAgent)]: chatForwardAgent,
	[stateName(keyErrandPreview)]: defAsyncReducer(
		keyErrandPreview
		, asyncInitState
	),
	[stateName(keyErrandUploadAnswerAttachment)]: uploadAnswerAttachment(),
	[stateName(keyErrandRemoveTemporaryAttachment)]: removeTemporaryAttachment(),
	[stateName(keyErrandWorkflowSettings)]: fetchWfSettings(),
	[stateName(keyForwardToAgent)]: forwardToAgent(),
	[stateName(keyForwardToArea)]: forwardToArea(),
	[stateName(keyGetErrands)]: getErrands(),
	[stateName(keyMoveToFolder)]: moveToFolder(),
	[stateName(keyQueueToMe)]: queueToMe(),
	[stateName(keyRetunToInbox)]: returnToInbox(),
	[stateName(keyLinkErrand)]: linkErrand(),
	[stateName(keyAgentSetErrandView)]: agentSetErrandView(),
	[stateName(keyWFExpertQueries)]: defAsyncReducer(keyWFExpertQueries,
		asyncInitState),
	[stateName(keyGetSlaTime)]: getSlaTime(),
	[stateName(keyConnectedAgentAreas)]: connectedAgentAreas(),
	[stateName(keyStatisticsAgentAreas)]: statisticsAgentAreas(),
	[stateName(keyCreateManualErrand)]: createManualErrand(),
	[stateName(keyIncomingEmailErrands)]: incomingEmailsList(),
	[stateName(keyAllActiveErrands)]: allActiveEmailsErrands(),
	[stateName(keyAllActiveAgents)]: allActiveAgents(),
	[stateName(keyFetchForwardedErrands)]: allForwardedErrands(),
	[stateName(keyFetchIncomingCollaborations)]: allIncomingCollabs(),
	[stateName(keyFetchDueErrandsInAWeek)]: allDueErrandsInAWeek(),
	[stateName(keyFetchExpiringErrands)]: allExpiringErrands(),
	[stateName(keyDeleteLaunchpadWidgets)]: removeLaunchpadWidget(),
	[stateName(keyAddLaunchpadWidgets)]: addLaunchpadWidget(),
	[stateName(keyQueueBasicErrandInfo)]: basicQueuedErrand(),
	listInputs: (st = listInputsInitState, act) => st,
	commentCount: (st = commentCountInitState, act) => st,
	ui: uiLocalReducer,
	filter: uiLocalFilter,
	sort: (state = contextSortInitState) => state,
	globalReady: (state = false, action) => {
		if (action.type !== SET_WORKFLOW_READY) {
			return state;
		}
		return true;
	}
});

const updateWorkflow = createRootUpdater('workflow');

function removeAcquiredErrandFromList(state, action, id) {
	const listStateName = stateName(keyErrandList)
		, currentContext = contextMemo(state)
		, list = getWorkflowRoot(state)[listStateName].data
		;
	if (!list
		|| !list.order
		|| !list.order.length
		|| !list.norm
		|| currentContext !== CTX_NEW) {
		return state;
	}
	const {
			order
			, norm
			, totalUpperCount
			, totalErrandCount
			, groupCount
			, totalGroupedErrands
			, hasTotalGroupedErrands
		} = list
		, errand = norm[id]
		;
	if (!errand) {
		return state;
	}
	// remove this errand from errand-list since the current context is All
	// Errands and the errand had already been owned.
	let found;
	$.each(order, (index, v) => {
		if (v === errand.id) {
			found = {index};
			return false;
		}
	});
	if (!found) {
		return state;
	}
	const { index } = found
		, { threadId } = errand
		, unset = {$unset: [errand.id+""]}
		;
	let newTotal = totalErrandCount
		, newGroupCount = groupCount[threadId]
		, newUpper = totalUpperCount
		;
	if (newTotal) {
		newTotal--;
	}
	if (newUpper) {
		newUpper--;
	}
	let groupReduced
		, updatedState = {
			opr: unset
			, norm: unset
			, order: {$splice: [[index, 1]]}
			, totalErrandCount: {$set: newTotal}
			, totalUpperCount: {$set: newUpper}
		}
		;
	if (newGroupCount) {
		newGroupCount--;
	}
	if (newGroupCount) {
		updatedState.groupCount = {[threadId]: {$set: newGroupCount}};
	} else {
		updatedState.groupCount = {$unset: [threadId]};
		groupReduced = true;
	}
	if (!hasTotalGroupedErrands) {
		updatedState.totalGroupedErrands = {$set: newTotal};
	} else if (groupReduced) {
		updatedState.totalGroupedErrands = {$set: totalGroupedErrands-1};
	}
	return updateWorkflow(state, {[listStateName]: {data: updatedState}});
}

function removeAcquiredErrandIDsFromAllErrands(state, action) {
	const { errands, isSwitching } = action.payload;
	if (isSwitching || !errands || !errands.length) {
		return state;
	}
	let newState = state;
	$.each(errands, (i, v) => {
		newState = removeAcquiredErrandFromList(newState, action, v);
	});
	return newState;
}

function removeAcquiredErrandFromAllErrands(state, action) {
	const { data } = action.payload;
	if (!data || !data.id || data.data.agentId <= 0) {
		return state;
	}
	return removeAcquiredErrandFromList(state, action, data.id);
}

const _updateAllErrandsReducers = {
	[errandDone(keyLoadBasicErrand)]: removeAcquiredErrandFromAllErrands
	, [errandDone(keyGetOneRelatedErrand)]: removeAcquiredErrandFromAllErrands
	, [errandDone(keyReloadOneErrand)]: removeAcquiredErrandFromAllErrands
	, [SYNC_RELATED_ERRANDS]: removeAcquiredErrandIDsFromAllErrands
};

const updateAllErrandsReducer = createReducer(
	emptyObject
	, _updateAllErrandsReducers
);

const updateWorkflowFilter = (state, updater) => update(state, {app: {workflow: {filter: updater}}});

const updateContextChangeReducer = (state, { type, payload }) => {
	if (type !== UPDATE_WF_PARAMS) {
		return state;
	} else if (typeof payload.folder !== "undefined") {
		if (payload.folder !== getSelectedFolderFilter(state)) {
			return updateWorkflowFilter(state, {contextChanged: {$set: true}});
		} else if (contextMemo(state) !== CTX_NEW) {
			return updateWorkflowFilter(state, {contextChanged: {$set: false}});
		}
		return state;
	}
	return updateWorkflowFilter(state, {contextChanged: {$set: false}});
};

const domainTransferReducers = {
	[XFER_DOMAIN_ERRAND_PREVIEW]: domainTransferReducer(stateName(keyErrandPreview)),
	[XFER_DOMAIN_WF_EXPERT_QUERIES]: domainTransferReducer(stateName(keyWFExpertQueries))
};

workflow = reduceReducers(
	workflow
	, createReducer({}, domainTransferReducers)
	, listInputsReducer
	, filterReducer
	, commentCountReducer
	, updateWorkflowSettingsReducer
	, setupDefaultWFSettings
	, setAgentDataFromLocalDb
);

export const app = {
	[done(keyErrandList)]: state => {
		// Below solve the issue where open review errand directly from a link
		// and has errand list selected the correct errand. However, it may give
		// some peformance issue. Need a better selected state where all
		// non-selected do not need any state. Clearing selected state can just
		// be setting empty object.
		const { id } = state.errand.currentErrand;
		if (id <= 0) {
			return state;
		}
		const name = stateName(keyErrandList);
		state = update(
			state
			, {workflow: {[name]: {$set: updateSelectAll(
				state.workflow[name]
				, false
			)}}}
		);
		if (!state.workflow[name].data.opr[id]) {
			return state;
		}
		return update(
			state
			, {workflow: {[name]: {data: {opr: {[id]: {$merge: {selected: true}}}}}}}
		);
	}
	,
	[done(keyQueueBasicErrandInfo)]: (state, action) => {
		
		let { data } = action.payload;
		let cQ = state.workflow.ui.queuedErrands;
		let id = data.id;
		let groupwithNum = data.groupWith;
		const name = "errandList";
		let currentContext = state.workflow.filter.currentContext;

		if (features["queued.event.raise"]) {

		if (currentContext == CTX_NEW) {
			let updatedState = state;
		
			cQ.forEach((item, index) => {
				if (item.qType === "area" && item.direction === "in") {
					updatedState = update(
						updatedState,
						{
							workflow: {
								[name]: {
									data: {
										groupCount: { $merge: { [id]: groupwithNum } },
										norm: { $merge: { [id]: data } },
										order: { $push: [id] }
									}
								},
								
						
							}
						}
					);
				} else if (item.qType === "area" && item.direction === "out") {
					updatedState = update(
						updatedState,
						{
							workflow: {
								[name]: {
									data: {
										groupCount: { $merge: { [id]: groupwithNum } },
										norm: { $splice: [[updatedState.workflow[name].data.norm.indexOf(id), 1]] },
										order: { $splice: [[updatedState.workflow[name].data.order.indexOf(id), 1]] },
									}
								},
							}
						}
					);
				}
			});
		
			return updatedState;
		
		} else if (currentContext == CTX_MY) {
			let updatedState = state;
		
			cQ.forEach((item, index) => {
				if (item.qType === "agent" && item.direction === "in") {
					const idExists = updatedState.workflow[name].data.norm.hasOwnProperty(id);

					if (!idExists) {
						updatedState = update(
							updatedState,
							{
								workflow: {
									[name]: {
										data: {
											groupCount: { $merge: { [id]: groupwithNum } },
											norm: { $merge: { [id]: data } },
											opr: { $merge: { [id]: { selected: false } } },
											order: { $push: [id] }
										}
									},
									
								}
							}
						);
					}
			
				} else if (item.qType === "agent" && item.direction === "out") {
					
				}
			});
		
			return updatedState;
		}else if (currentContext == CTX_POSTPONED) {
			let updatedState = state;
		
			cQ.forEach((item, index) => {
				if (item.qType === "agent_postponed" && item.direction === "in") {
					updatedState = update(
						updatedState,
						{
							workflow: {
								[name]: {
									data: {
										groupCount: { $merge: { [id]: groupwithNum } },
										norm: { $merge: { [id]: data } },
										opr: { $merge: { [id]: { selected: false } } },
										order: { $push: [id] }
									}
								},
								
								
							}
						}
					);
				} else if (item.qType === "agent_postponed" && item.direction === "out") {
					updatedState = update(
						updatedState,
						{
							workflow: {
								[name]: {
									data: {
										groupCount: { $merge: { [id]: groupwithNum } },
										norm: { $splice: [[updatedState.workflow[name].data.norm.indexOf(id), 1]]  },
										order: { $splice: [[updatedState.workflow[name].data.order.indexOf(id), 1]] },
									}
								},	
							}
						}
					);
				}
			});
		
			return updatedState;
		
		}
		return state;
	}
	return state;
	}
};

export const rootReducer = reduceReducers(
	updateAllErrandsReducer
	, updateContextChangeReducer
);

export default workflow;
